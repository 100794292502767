import React, { useState, useEffect } from 'react'
import { Form } from 'react-final-form'
import { Link } from 'gatsby'
import BootstrapForm from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import styled from 'styled-components'

import { formatDate } from '../shared/date-utils'
import EMail from '../shared/email'
import { FileInput, PointInputOrNA } from './inputs'
import { requiredPDF, validPoints } from './validators'
import { getReference, submitReference } from './rest-api'
import { Heading } from '../layout/tableofcontent/heading'
import { SubmitButton } from './shared'

const Box = styled.div`
  padding: 12px;
`
export const ReferenceForm: React.FC<any> = ({ id }) => {
  const [reference, setReference] = useState<any>(null)
  useEffect(() => {
    getReference(id).then((reference) => setReference(reference))
  }, [id])

  if (!id) {
    return <Alert>Reference ID is required.</Alert>
  }
  if (!reference) {
    return <>Loading reference request...</>
  }
  if (reference.error) {
    return <Alert>Error: {reference.error}</Alert>
  }

  const { received, name: referenceName } = reference
  const application = reference.application
  const {
    job: { title: jobName, slug },
    firstName,
    lastName,
  } = application
  const applicant = `${application.firstName} ${application.lastName}`
  return (
    <>
      {received ? (
        <Alert variant="success">
          Thank you very much for submitting your reference letter. It has been
          well received on{' '}
          {formatDate('dddd, MMMM Do YYYY, HH:mm:ss')(received)}. The applicant
          has been informed via email, too. There is nothing more to be done,
          except in case you want to modify your letter or overall impression.
          In this case, we kindly ask you to resubmit the form below.
        </Alert>
      ) : null}
      <p>
        <p>Dear Colleague,</p>I received an application from
        <Box>
          <b>{applicant}</b>
        </Box>
        for the opening
        <Box>
          <Link to={`/jobs/${slug}`}>"{jobName}"</Link>
        </Box>
        It indicates that you
        <Box>
          <b>{referenceName}</b>
        </Box>
        agreed to provide a reference letter. May I therefore kindly ask you to
        complete the following form. Thanks a lot. Your help is very much
        appreciated. If you encounter any problems, please send an email to{' '}
        <EMail>falk.hassler@uwr.edu.pl</EMail>.
      </p>
      <Form
        initialValues={{ scientific: null, social: null }}
        onSubmit={async (values) => {
          setReference(await submitReference(id, values))
          setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 200)
        }}
        render={({ handleSubmit, invalid, form, submitting }) => (
          <BootstrapForm
            onSubmit={async (event) => {
              await handleSubmit(event)
              form.reset()
            }}
          >
            <Heading level={3}>Overall impression</Heading>
            <p>
              I would appreachiate if you could share in addition to your letter
              your overall impression of the candidate. The answers to the
              following questions are on a scale from 0 (worst) to 10 (best).
              Best here means compared to all peope in the same career stage you
              have worked with. I know that answering these questions might be
              difficualt and if you don't want to or can't answer them, please
              check the corresponding box.
            </p>
            <br />
            <PointInputOrNA
              name="scientific"
              label="Scientific potential"
              description="How would you rate the scientific potential of the candidate compared to other people at the same career stage you supervised?"
              min={0}
              max={10}
              steps={1}
              validate={validPoints}
            />
            <PointInputOrNA
              name="social"
              label="Social skills"
              description="How do you assess the candidates social skills? I.e. instructing students, being open to new ideas, sharing knowledge, ..."
              min={0}
              max={10}
              steps={1}
              validate={validPoints}
            />
            <Heading level={3}>Reference letter</Heading>
            <FileInput
              name="files.letter"
              label="Reference letter"
              description="Please upload your letter in PDF format."
              validate={requiredPDF}
            />
            <SubmitButton submitting={submitting} invalid={invalid} />
          </BootstrapForm>
        )}
      />
    </>
  )
}
