import React from 'react'

import { Heading } from '../layout/tableofcontent/heading'
import Course from '../course'

const ContentCourseList = ({ content: { courses, headingLevel } }) => {
  return courses.map((course) => (
    <>
      {headingLevel ? (
        <Heading level={headingLevel} style={{ display: 'inline-block' }}>
          {course.name}
        </Heading>
      ) : null}
      <Course course={course} />
    </>
  ))
}

export default ContentCourseList
