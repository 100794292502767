import React from 'react'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import styled from 'styled-components'

import { formatDate } from '../shared/date-utils'

const DeadlineContainer = styled.div`
  padding: 12px;
`

export const Deadline: React.FC<any> = ({ deadline }) => (
  <DeadlineContainer>
    <b>{formatDate('dddd, MMMM Do YYYY, HH:mm:ss z')(deadline)}</b>
  </DeadlineContainer>
)

export const SubmitButton: React.FC<any> = ({ submitting, invalid }) => (
  <Button type="submit" variant="primary" disabled={invalid}>
    {submitting ? (
      <>
        <Spinner className="mx-2" size="sm" role="status" aria-hidden="true" />
        Submitting...
      </>
    ) : (
      'Submit'
    )}
  </Button>
)
